import { FormsFieldPreset, ConnectedElementPreset } from '../../../../../constants/field-types'
import { loginEmailData } from './definitions/login-email'
import { passwordData } from './definitions/password'
import { checkboxAgreeTermsData } from './definitions/checkbox-agree-terms'
import { checkboxJoinCommunityData } from './definitions/checkbox-join-community'
import { linkToLoginData } from './definitions/link-to-login'
import { privacyPolicyData } from './definitions/privacy-policy'
import { codeOfConductData } from './definitions/code-of-conduct'
import { termsOfUseData } from './definitions/terms-of-use'

export const registrationFormFields = () => ({
  [FormsFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL]: loginEmailData,
  [FormsFieldPreset.REGISTRATION_FORM_PASSWORD]: passwordData,
  [FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: checkboxAgreeTermsData,
  [FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: checkboxJoinCommunityData,
  [ConnectedElementPreset.REGISTRATION_FORM_LINK_TO_LOGIN]: linkToLoginData,
  [ConnectedElementPreset.REGISTRATION_FORM_PRIVACY_POLICY]: privacyPolicyData,
  [ConnectedElementPreset.REGISTRATION_FORM_CODE_OF_CONDUCT]: codeOfConductData,
  [ConnectedElementPreset.REGISTRATION_FORM_TERMS_OF_USE]: termsOfUseData,
})
