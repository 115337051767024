import * as _ from 'lodash'
import { ROLE_FORM, ROLE_MESSAGE } from '../../../constants/roles'
import { applyStyle, applyPropsAndData } from '../preset/preset-styles'
import { getFormPreset, getFieldPreset } from '../preset/preset-service'
import { getFieldStyle, commonStyles } from './form-style-service'
import { getHiddenMessagePreset, getSubmitButtonPreset } from '../components/form-structures'
import translations from './translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { SuccessActionTypes, SecondsToResetDefaults } from '../../../constants/success-settings'
import { FormPreset } from '../../../constants/form-types'
import { ButtonType, FieldPreset, MessageType } from '../../../constants/field-types'
import { formComponent } from '../api-types'

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

const convertFormComponents = formComponent => {
  return {
    box: convertToInnerStructure(_.omit(formComponent, 'components')),
    fields: formComponent.components.map(convertToInnerStructure),
  }
}

const getConnectionConfig = (presetKey, comp) => {
  if (comp.role !== ROLE_FORM) {
    return {}
  }
  const config = {
    preset: presetKey,
    labels: ['contacts-contacted_me'],
    errorMessage:
      presetKey === FormPreset.REGISTRATION_FORM
        ? translations.t('settings.errorMessage.registrationForm')
        : translations.t('settings.errorMessage.default'),
    secondsToResetForm: SecondsToResetDefaults.MIN,
    successActionType: SuccessActionTypes.SHOW_MESSAGE,
    styles: {
      input: {
        bg: 'color_11',
        bge: 'color_11',
        bgf: 'color_11',
        bgh: 'color_11',
        brd: 'color_15',
        brde: '#f60419',
        brdf: 'color_18',
        brdh: 'color_15',
        fnt: 'font_8',
        txt: 'color_15',
        txt2: 'color_14',
      },
      box: { bg: 'color_11' },
    },
  }
  return config
}

export const getExtraMessageText = ({ data, presetKey = '', newSuccessMessage }) => ({
  text: data.text.replace(
    new RegExp(`>${escapeRegExp(innerText(data.text))}`),
    `>${newSuccessMessage ||
      (presetKey === FormPreset.REGISTRATION_FORM
        ? translations.t('settings.errorMessage.registrationForm')
        : translations.t('settings.successMessage.default'))}`
  ),
})

const getChildComponents = (presetKey, comp, theme) =>
  comp.components &&
  comp.components.map(childComp => deConstructComponent({ presetKey, rawComp: childComp, theme }))

const deConstructComponent = ({
  presetKey,
  rawComp,
  theme = null,
  newSuccessMessage = null,
}: formComponent) => {
  let comp = rawComp
  // TODO: Merge this change (remove theme, applyPropsAndData) after we drop the old add panel
  if (theme) {
    const compWithStyle = applyStyle(rawComp, theme, presetKey)
    comp = applyPropsAndData(compWithStyle, theme)
  }

  comp.connectionConfig = _.merge(
    {},
    comp.config,
    getConnectionConfig(presetKey, comp),
    comp.connectionConfig
  )
  if (comp.role === ROLE_MESSAGE) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newSuccessMessage })
    )
  }
  comp.components = getChildComponents(presetKey, comp, theme)
  return comp
}

export const fetchPreset = async (
  presetKey: FormPreset,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop
) => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(<Error>e).message}`)
    return
  }
  return rawPreset
}

// return { box, fields }
export const createForm = async (
  presetKey,
  { coords = { x: 175 }, theme = null, locale = 'en' } = {},
  onFailedPresetCallback = _.noop
) => {
  const rawPreset = await fetchPreset(
    presetKey,
    locale,
    onFailedPresetCallback
  )
  if (!rawPreset) {
    return
  }

  const formComponent = _.merge(
    {
      style: {
        style: {
          properties: {},
        },
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
      },
    },
    deConstructComponent({ presetKey, rawComp: rawPreset, theme }),
    {
      layout: coords || { x: 175 },
    }
  )

  delete formComponent.layout.y
  const { box, fields } = convertFormComponents(formComponent)

  return { box, fields, plugin: _.get(rawPreset, 'config.plugins') }
}

// return { role, connectionConfig, data }
export const createField = (
  presetKey,
  {
    fieldType,
    extraData,
    commonStyles,
  }: { fieldType: FieldPreset; extraData: any; commonStyles: commonStyles },
  layout,
  plugins
) => {
  // TODO remove presetKey
  const rawPreset = getFieldPreset({ fieldType, extraData, plugins })
  const fieldComponent = _.merge({}, deConstructComponent({ presetKey, rawComp: rawPreset }), {
    layout,
  })
  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  _.assign(fieldComponent.style.style.properties, fieldStyle)
  return convertToInnerStructure(fieldComponent)
}

// return { role, connectionConfig, data }
export const createSubmitButton = async (
  { layout, buttonType },
  { presetKey, locale, onFailedPresetCallback },
) => {
  const rawPreset = await getSubmitButtonPreset(buttonType, {
    presetKey,
    locale,
    onFailedPresetCallback,
  })
  const data = {
    label: translations.t(
      `preset.${buttonType === ButtonType.SUBMIT ? 'submit' : 'signup'}ButtonLabel`
    ),
  }
  const buttonComponent = _.merge(
    {},
    deConstructComponent({ presetKey: '', rawComp: rawPreset }),
    {
      layout,
      data,
    }
  )
  return convertToInnerStructure(buttonComponent)
}

// return { role, connectionConfig, data }
export const createHiddenMessage = async ({ layout, hiddenMessageType, newSuccessMessage }, { presetKey, locale, onFailedPresetCallback }) => {
  const rawPreset = await getHiddenMessagePreset(hiddenMessageType, { presetKey, locale, onFailedPresetCallback })
  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      presetKey: hiddenMessageType === MessageType.REGISTRATION ? FormPreset.REGISTRATION_FORM : '',
      rawComp: rawPreset,
      newSuccessMessage,
    }),
    { layout }
  )
  return convertToInnerStructure(hiddenMessageComponent)
}
