import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { FieldData, FieldProperties } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeGeneralRecaptcha = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.BOOLEAN,
    componentType: COMPONENT_TYPES.RECAPTCHA,
    extraData: {
      role: FIELDS.ROLE_FIELD_RECAPTCHA,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_RECAPTCHA}`),
      },
      data: {},
    },
  }
}

export const generalRecaptchaData: FieldData = {
  make: makeGeneralRecaptcha,
  customFields: [],
  icon: iconNames.recaptcha
}
