import { AdiFieldPreset } from '../../../../../constants/field-types'
import { adiAddressData } from './definitions/adi-address'
import { adiPhoneData } from './definitions/adi-phone'
import { adiEmailData } from './definitions/adi-email'
import { adiURLData } from './definitions/adi-url'
import { adiNumberData } from './definitions/adi-number'
import { adiDateData } from './definitions/adi-date'
import { adiFirstNameData } from './definitions/adi-first-name'
import { adiLastNameData } from './definitions/adi-last-name'

export const adiFormFields = () => ({
  [AdiFieldPreset.ADI_EMAIL]: adiEmailData,
  [AdiFieldPreset.ADI_PHONE]: adiPhoneData,
  [AdiFieldPreset.ADI_ADDRESS]: adiAddressData,
  [AdiFieldPreset.ADI_URL]: adiURLData,
  [AdiFieldPreset.ADI_DATE]: adiDateData,
  [AdiFieldPreset.ADI_NUMBER]: adiNumberData,
  [AdiFieldPreset.ADI_FIRST_NAME]: adiFirstNameData,
  [AdiFieldPreset.ADI_LAST_NAME]: adiLastNameData,
})
