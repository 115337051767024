import CoreApi from '../core-api';
import { ComponentRef } from '../api-types';

const RegistrationFormApi = (_coreApi): Partial<CoreApi> => {
  const getCrucialElements = (_componentRef: ComponentRef) => {
    // TODO: Implement different behavior for registration form
    return null
  }

  return {
    settings: {
      getCrucialElements: getCrucialElements
    }
  }
}

export default RegistrationFormApi
