import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { generalSingleCheckboxData } from '../../general-fields/definitions/general-single-checkbox'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { FieldRenderConfigType } from '../../constants';
import { FormPlugin } from '../../../../../../constants/plugins';

const makeCheckboxJoinCommunity = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalSingleCheckboxData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY}`),
      },
      data: {
        label: t(`fieldTypes.${FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY}.label`),
      },
    },
  })
}

export const checkboxJoinCommunityData: FieldData = {
  make: makeCheckboxJoinCommunity,
  customFields: [],
  icon: generalSingleCheckboxData.icon,
  renderConfig: {
    [FormPlugin.REGISTRATION_FORM]: {
      crmSync: FieldRenderConfigType.REMOVE
    }
  }
}
