import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { COMPONENT_TYPES } from '../../component-types'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { generalSingleCheckboxData } from './../../general-fields/definitions/general-single-checkbox'
import { FieldRenderConfigType } from '../../constants';
import { FormPlugin } from '../../../../../../constants/plugins';

const makeCheckboxAgreeTerms = (): FieldProperties => {
  const t = translations.t.bind(translations)
  return _.merge(
    {},
    {
      componentType: COMPONENT_TYPES.SINGLE_CHECKBOX,
      extraData: {
        role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS,
        connectionConfig: {
          crmType: CRM_TYPES.CUSTOM_FIELD,
          crmLabel: t(`fieldTypes.${FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}`),
        },
        props: {
          required: true,
        },
        data: {
          label: t(`fieldTypes.${FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}.label`),
        },
      },
    }
  )
}

export const checkboxAgreeTermsData: FieldData = {
  make: makeCheckboxAgreeTerms,
  customFields: [],
  icon: generalSingleCheckboxData.icon,
  renderConfig: {
    [FormPlugin.REGISTRATION_FORM]: {
      crmSync: FieldRenderConfigType.REMOVE,
      required: FieldRenderConfigType.DISABLED,
      internalName: FieldRenderConfigType.DISABLED,
      checkedByDefault: FieldRenderConfigType.DISABLED,
    }
  }
}
